<template>
  <div class="page-container">
    <div class="page-body">
      <div class="header">
        <div class="title">南大格儒文化</div>
        <div class="subtitle">在职研究生报名表</div>
      </div>
      <div class="content">
        <div class="date-body">
          <ul>
            <li>填报日期：</li>
            <li>{{ formData.gmtCreate | momentFormater("YYYY") }}</li>
            <li>年</li>
            <li>{{ formData.gmtCreate | momentFormater("MM") }}</li>
            <li>月</li>
            <li>{{ formData.gmtCreate | momentFormater("DD") }}</li>
            <li>日</li>
          </ul>
        </div>
        <div class="table-body">
          <table class="table" width="100%" border="1">
            <tr>
              <td width="17%" colspan="2">姓名</td>
              <td width="14%" colspan="2">
                {{ formData.name }}
              </td>
              <td width="14%">性别</td>
              <td width="12%" v-if="formData.sex == 1">男</td>
              <td width="12%" v-if="formData.sex == 2">女</td>
              <td width="14%" colspan="2">民族</td>
              <td width="12%">
                {{ formData.nation }}
              </td>
              <td rowspan="4" width="15%">
                <img class="avatar" :src="formData.certificatePhotoPath" />
              </td>
            </tr>
            <tr>
              <td colspan="2">出生日期</td>
              <td colspan="2">
                {{ formData.birthday }}
              </td>
              <td>籍贯</td>
              <td>
                {{ formData.registerResidence }}
              </td>
              <td colspan="2">政治面貌</td>
              <td>
                {{ formData.politicalOutlook }}
              </td>
            </tr>
            <tr>
              <td colspan="2">身份证号</td>
              <td colspan="3">
                {{ formData.idCard }}
              </td>
              <td>联系电话</td>
              <td colspan="3">
                {{ formData.phone }}
              </td>
            </tr>
            <tr>
              <td colspan="2">爱好、特长</td>
              <td colspan="3">
                {{ formData.hobby }}
              </td>
              <td>邮箱地址</td>
              <td colspan="3">
                {{ formData.email }}
              </td>
            </tr>
            <tr>
              <td>现工作单位</td>
              <td colspan="4">
                {{ formData.workUnit }}
              </td>
              <td>职务</td>
              <td colspan="4">
                {{ formData.position }}
              </td>
            </tr>
            <tr>
              <td>通讯地址</td>
              <td colspan="9">
                {{ formData.address }}
              </td>
              </tr>
            <tr>
              <td>最高学历学位</td>
              <td colspan="4">
                {{ formData.preExamEdu }}
              </td>
              <td>所学专业</td>
              <td colspan="4">
                {{ formData.gradMajor }}
              </td>
            </tr>
            <tr>
              <td>最高学位证编号</td>
              <td colspan="4">
                {{ formData.gradCardNum }}
              </td>
              <td>学位证发证时间</td>
              <td colspan="4">
                {{ formData.gradTime }}
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <h2 style="text-align: center;">教育、工作经历（从大学填起）</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">起止年月</td>
              <td colspan="6">学习或工作单位（学习期间请注明学校、学科专业）</td>
              <td colspan="2">任何职务</td>
            </tr>
            <template >
              <tr v-for="item, index in formData.experienceList" :key="index">
                <td colspan="2">
                  <div style="display: flex;justify-content:center;">
                    {{ item.beginTime ? item.beginTime : '' }}
                      -
                    {{ item.endTime ? item.endTime : '' }}
                  </div>
                </td>
                <td colspan="6">
                  {{ item.unit }}
                </td>
                <td colspan="2">
                  {{ item.position }}
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="10">
                <h2 style="text-align: center;">紧急联系人信息</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">姓名</td>
              <td colspan="2">与本人关系</td>
              <td colspan="4">在何单位工作、任何职务</td>
              <td colspan="2">联系电话</td>
            </tr>
            <tr>
              <td colspan="2">
                {{ formData.emerContactPerson }}
              </td>
              <td colspan="2">
                {{ formData.emerContactRealtion }}
              </td>
              <td colspan="4">
                {{ formData.emerContactUnit }}
              </td>
              <td colspan="2">
                {{ formData.emerContactPhone }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    formData: {
      type: Object
    }
  },
  data () {
    return {}
  }
}
</script>

<style></style>
