<template>
  <!-- 更改推广员 -->
  <div class="page-coantainer">
    <el-dialog
      title="更改推广员"
      :visible.sync="promoterData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" label-width="80px">
          <el-form-item label="推广员" prop="applySchoolId">
            <el-select
              v-model="formData.promoterId"
              filterable
              placeholder="请输入选择更改的推广员"
            >
              <el-option
                v-for="item in promoterList"
                :label="item.name + '-' + item.phone"
                :value="item.id"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="updatePromoter">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updatePromoterToStu, getPromoterUserList } from '@/api/api.js'
export default {
  props: {
    promoterData: {
      type: Object
    }
  },
  watch: {
    'promoterData.isOpen' () {
      this.searchPromoter()
    }
  },
  data () {
    return {
      formData: {
        id: '',
        promoterId: ''
      },
      promoterList: []
    }
  },
  methods: {
    // 获取所有推广员
    searchPromoter (query) {
      console.log(11)
      const queryInfo = {
        currentPage: 1,
        keyword: query,
        pageSize: 99999
      }

      getPromoterUserList(queryInfo).then(res => {
        this.formData.promoterId = ''
        this.promoterList = res.data.list
      })
    },
    // 更改推广员
    updatePromoter () {
      this.formData.id = this.promoterData.id
      if (!this.formData.promoterId) return false
      this.$refs.form.validate(valid => {
        if (valid) {
          updatePromoterToStu(this.formData).then(res => {
            this.$emit('closeDialog', {
              isOpen: false,
              type: 'success'
            })
            this.$message({
              message: '更改成功',
              type: 'success'
            })
          })
        }
      })
    },
    // 选择推广员
    changePromoter (promoterId) {
      this.formData.promoterId = promoterId
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style></style>
