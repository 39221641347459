<template>
  <div class="page-coantainer">
    <el-dialog
      title="报名表"
      :visible.sync="updateData.isOpen"
      width="60%"
      :before-close="handleClose"
    >
      <print-temp
        id="sign-body"
        v-show="false"
        :formData="formData"
      ></print-temp>
      <preview-dialog
        :formData="formData"
        ref="student"
        @updateSuccess="updateSuccess"
      ></preview-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="updateStudent">确 定</el-button>
        <el-button type="primary" @click="exportOneStuInfo">导 出</el-button>
        <el-button type="primary" @click="printSignForm">打 印</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import previewDialog from '../components/previewSign2.vue'
import printTemp from '../components/printTemplate2.vue'
import { getStudentInfoById } from '@/api/api'
import { exportWordService } from '@/api/excel'

export default {
  components: {
    previewDialog,
    printTemp
  },
  props: {
    updateData: {
      type: Object
    }
  },
  data () {
    return {
      TEMPLATE_HTML_URL: `${process.env.BASE_URL}print/index.html`,
      isOpenDialog: false,
      formData: {}
    }
  },
  watch: {
    'updateData.isOpen' (data) {
      if (data) {
        this.getStudentInfoById(this.updateData.id)
      }
    }
  },
  methods: {
    /* 根据id获取学生信息 */
    getStudentInfoById (id) {
      getStudentInfoById(id).then(res => {
        this.formData = res.data
        if (this.formData.experienceListJson) {
          this.formData.experienceList = JSON.parse(this.formData.experienceListJson)
        }
        // this.formData.experienceList = [
        //   {
        //     beginTime: '',
        //     endTime: '',
        //     unit: '工地',
        //     position: '打灰'
        //   }
        // ]
      })
    },

    /* 更新学生信息 */
    updateStudent () {
      this.$refs.student.updateStudent()
    },

    /* 更新成功 */
    updateSuccess () {
      this.$emit('closeDialog', { isOpen: false, type: 'success' })
    },

    /* 打印报名表单 */
    printSignForm () {
      const report = document.querySelector('#sign-body')
      const iframe = document.createElement('iframe')
      const iframeStyle =
        'border:0;position:absolute;width:0px;height:0px;right:0px;top:0px;'
      iframe.style.cssText = iframeStyle
      const id = new Date().getTime()
      iframe.id = id
      iframe.src = this.TEMPLATE_HTML_URL
      document.body.appendChild(iframe)
      iframe.onload = () => {
        iframe.contentDocument.querySelector(
          '#signForm'
        ).innerHTML = this.nodeToString(report)
        setTimeout(() => {
          iframe.contentWindow.print()
        }, 2000)
        setTimeout(() => {
          iframe.remove()
        }, 2000)
      }
    },

    // 导出学生信息为word文档
    exportOneStuInfo () {
      const id = this.formData.id
      const name = this.formData.name
      exportWordService.exportOneStuInfo(id, name)
    },

    nodeToString (node) {
      let tmpNode = document.createElement('div')
      tmpNode.appendChild(node.cloneNode(true))
      const str = tmpNode.innerHTML
      tmpNode = node = null
      return str
    },

    // 关闭弹窗
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
