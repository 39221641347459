<template>
  <div class="page-container">
    <div class="page-body">
      <div class="header">
        <div class="title">南大格儒文化</div>
        <div class="subtitle">在职研究生报名表</div>
      </div>
      <div class="content">
        <div class="date-body">
          <ul>
            <li>填报日期：</li>
            <li>{{ formData.gmtCreate | momentFormater("YYYY") }}</li>
            <li>年</li>
            <li>{{ formData.gmtCreate | momentFormater("MM") }}</li>
            <li>月</li>
            <li>{{ formData.gmtCreate | momentFormater("DD") }}</li>
            <li>日</li>
          </ul>
        </div>
        <div class="table-body">
          <table class="table" width="100%" border="1">
            <tr>
              <td width="17%" colspan="2">姓名</td>
              <td width="14%" colspan="2">
                <el-input v-model="formData.name"></el-input>
              </td>
              <td width="14%">性别</td>
              <td width="12%">
                <el-select v-model="formData.sex" placeholder="请选择">
                  <el-option label="男" :value="Number(1)"> </el-option>
                  <el-option label="女" :value="Number(2)"> </el-option>
                </el-select>
              </td>
              <td width="14%" colspan="2">民族</td>
              <td width="12%">
                <el-input v-model="formData.nation"></el-input>
              </td>
              <td rowspan="4" width="15%">
                <img class="avatar" :src="formData.certificatePhotoPath" />
              </td>
            </tr>
            <tr>
              <td colspan="2">出生日期</td>
              <td colspan="2">
                <el-input v-model="formData.birthday"></el-input>
              </td>
              <td>籍贯</td>
              <td>
                <el-input v-model="formData.registerResidence"></el-input>
              </td>
              <td colspan="2">政治面貌</td>
              <td>
                <el-input v-model="formData.politicalOutlook"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="2">身份证号</td>
              <td colspan="3">
                <el-input v-model="formData.idCard"></el-input>
              </td>
              <td>联系电话</td>
              <td colspan="3">
                <el-input v-model="formData.phone"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="2">爱好、特长</td>
              <td colspan="3">
                <el-input v-model="formData.hobby"></el-input>
              </td>
              <td>邮箱地址</td>
              <td colspan="3">
                <el-input v-model="formData.email"></el-input>
              </td>
            </tr>
            <tr>
              <td>现工作单位</td>
              <td colspan="4">
                <el-input v-model="formData.workUnit"></el-input>
              </td>
              <td>职务</td>
              <td colspan="4">
                <el-input v-model="formData.position"></el-input>
              </td>
            </tr>
            <tr>
              <td>通讯地址</td>
              <td colspan="9">
                <el-input v-model="formData.address"></el-input>
              </td>
              </tr>
            <tr>
              <td>最高学历学位</td>
              <td colspan="4">
                <el-input v-model="formData.preExamEdu"></el-input>
              </td>
              <td>所学专业</td>
              <td colspan="4">
                <el-input v-model="formData.gradMajor"></el-input>
              </td>
            </tr>
            <tr>
              <td>最高学位证编号</td>
              <td colspan="4">
                <el-input v-model="formData.gradCardNum"></el-input>
              </td>
              <td>学位证发证时间</td>
              <td colspan="4">
                <el-input v-model="formData.gradTime"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <h2 style="text-align: center;">教育、工作经历（从大学填起）</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">起止年月</td>
              <td colspan="6">学习或工作单位（学习期间请注明学校、学科专业）</td>
              <td colspan="2">任何职务</td>
            </tr>
            <template >
              <tr v-for="item, index in formData.experienceList" :key="index">
                <td colspan="2">
                  <div style="display: flex;">
                    <el-input v-model="item.beginTime"></el-input>
                      -
                    <el-input v-model="item.endTime"></el-input>
                  </div>
                </td>
                <td colspan="6">
                  <el-input v-model="item.unit"></el-input>
                </td>
                <td colspan="2">
                  <el-input v-model="item.position"></el-input>
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="10">
                <h2 style="text-align: center;">紧急联系人信息</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">姓名</td>
              <td colspan="2">与本人关系</td>
              <td colspan="4">在何单位工作、任何职务</td>
              <td colspan="2">联系电话</td>
            </tr>
            <tr>
              <td colspan="2">
                <el-input v-model="formData.emerContactPerson"></el-input>
              </td>
              <td colspan="2">
                <el-input v-model="formData.emerContactRealtion"></el-input>
              </td>
              <td colspan="4">
                <el-input v-model="formData.emerContactUnit"></el-input>
              </td>
              <td colspan="2">
                <el-input v-model="formData.emerContactPhone"></el-input>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateStudent } from '@/api/api'

export default {
  props: {
    formData: {
      type: Object
    }
  },
  formData () {
    return {}
  },
  methods: {
    /* 更新学生信息 */
    updateStudent () {
      updateStudent(this.formData).then((res) => {
        this.$emit('updateSuccess')
        this.$message({
          message: '更新成功',
          type: 'success'
        })
      })
    },
    changeDatePicker (e) {
      console.log(e)
      console.log('打印')
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .page-body {
    .flexBox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header {
      @extend .flexBox;
      flex-direction: column;
      padding-bottom: 40px;
      .title,
      .subtitle {
        font-size: 30px;
        padding: 5px 0;
        font-weight: bold;
      }
      .title {
        letter-spacing: 10px;
      }
    }
    .content {
      .date-body {
        padding: 10px 0;
        ul {
          display: flex;
        }
      }
      .table-body {
        .table {
          width: 100%;
          border-collapse: collapse;
          tr {
            td {
              text-align: center;
              padding: 0 10px;
              font-size: 14px;
              line-height: 40px;
              height: 40px;
              h2 {
                text-align: left;
              }
              .avatar {
                width: 100%;
                max-height: 300px;
                min-height: 150px;
                vertical-align: middle;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
</style>
